<template>
  <div class="container">
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>景区管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="AddDialogVisible = true"
            >添加景区</el-button
          >
        </el-col>
      </el-row>

      <el-table :data="scenicList" border style="width: 100%">
        <el-table-column prop="id" label="id" width="120" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="景区名称"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="invokeFlag"
          label="启用状态"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.invokeFlag === 1">启用</span>
            <span v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          label="修改时间"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updateUser"
          label="修改人"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="picture"
          label="景区图片"
          width="320"
          hidden="200"
          align="center"
        >
          <template slot-scope="scope">
            <img style="width: 200px; height: 100px" :src="scope.row.picture" />
          </template>
        </el-table-column>
        <el-table-column
          prop="introduce"
          label="景区介绍"
          width="320"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="longitude"
          label="景区纬度"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="latitude"
          label="景区纬度"
          width="120"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="signInScope"
          label="打卡范围/米"
          width="120"
          align="center"
        >
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="170px" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="edit(scope.row)"
              >
              </el-button>
            </el-tooltip>

            
            <el-tooltip effect="dark" content="删除" placement="top">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
          <!-- 分配角色 -->
          <!-- <el-button type="warning" icon="el-icon-setting" size="mini"></el-button> -->
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加对话框 -->
    <el-dialog
      title="添加景区"
      :visible.sync="AddDialogVisible"
      width="50%"
      :before-close="AddPopupClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="景区名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="景区图片">
          <el-upload
            class="avatar-uploader"
            :headers="headers"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="ImageUrl" :src="ImageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="景区经度">
          <el-input v-model="form.longitude"></el-input>
        </el-form-item>
        <el-form-item label="景区纬度">
          <el-input v-model="form.latitude"></el-input>
        </el-form-item>
        <el-form-item label="打卡范围/米">
          <el-input v-model="form.signInScope"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="additionArea">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改景区弹窗 -->
    <el-dialog
      title="修改景区"
      :visible.sync="updateDialogVisible"
      width="50%"
      :before-close="updateClose"
    >
      <el-form ref="updateForm" :model="form" label-width="80px">
        <el-form-item label="景区名称">
          <el-input v-model="updateForm.name"></el-input>
        </el-form-item>
        <el-form-item label="景区图片">
          <el-upload
            class="avatar-uploader"
            :headers="headers"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="updateForm.picture"
              :src="updateForm.picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="景区经度">
          <el-input v-model="updateForm.longitude"></el-input>
        </el-form-item>
        <el-form-item label="景区纬度">
          <el-input v-model="updateForm.latitude"></el-input>
        </el-form-item>
        <el-form-item label="打卡范围/米">
          <el-input v-model="updateForm.signInScope"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ModifyExecution">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState([
      "apiUrl", // 假设你想要映射 'apiUrl' 状态
    ]),
  },
  created() {
    console.log("刷新没");
    this.getScenic();
    this.getUrl_();
  },
  methods: {
    ...mapMutations(["updateApiUrl"]),
    ...mapGetters(["getApiUrl"]),
    edit(row) {
      console.log(row);
      this.updateForm = row;
      this.updateDialogVisible = true;
      
    },
    //获取景区
    getScenic() {
      const params = {
        pageNumber: 1,
        pageSize: 10,
        name: null,
        invokeFlag: 1,
      };
      this.$http
        .post("/api/scenicArea/pageSearch", params)
        .then((response) => {
          // 处理响应
          console.log("response.data====", response.data.data.data);
          this.scenicList = response.data.data.data;
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    AddPopupClose() {
      this.AddDialogVisible = false;
    },
    onSubmit() {
      console.log("submit!");
    },
    additionArea() {
      console.log("添加景区", this.form);
      const token = localStorage.getItem("token");
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      this.$http
        .post("/api/scenicArea/insert", this.form, headers)
        .then((response) => {
          // 处理响应
          console.log("response====", response.data);
          this.getScenic();
          this.$message({
            message: response.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
        this.AddDialogVisible = false;
        
    },
    handleAvatarSuccess(res, file) {
      //接收服务器返回的图片地址
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.data) {
        this.ImageUrl = res.data;
        this.updateForm.picture = res.data;
        this.form.picture = res.data;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const acceptedMimes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      const isLt10M = file.size / 1024 / 1024 < 20;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!acceptedMimes.includes(file.type)) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 或 WEBP 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      // return isJPG && isLt2M;
      return acceptedMimes.includes(file.type) && isLt10M;
    },
    getUrl_() {
      let ApiBaseUrl = this.getApiUrl();
      console.log("hh", ApiBaseUrl);
      this.uploadUrl = ApiBaseUrl + "/api/file/uploadOss";
      console.log("this.getApiUrl", ApiBaseUrl);
      console.log("this.uploadUrl", this.uploadUrl);
    },
    updateClose() {
      this.updateDialogVisible = false;
    },
    ModifyExecution() {
      const token = localStorage.getItem("token");
      console.log("this.updateForm==", this.updateForm);
      this.updateForm.pictureList = [];
      this.updateForm.videoList = [];
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      this.$http
        .post("/api/scenicArea/update", this.updateForm, headers)
        .then((response) => {
          // 处理响应
          console.log("response====", response.data);
          this.$message({
            message: response.data.message,
            type: "success",
          });
          // 刷新页面
          this.getScenic();
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
      this.updateDialogVisible = false;
    },
    handleDelete(row){
      
      const id = row.id;
      console.log('删除--->>>>',id);

      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        idList: [id],
      };
      this.$http
        .post("/api/scenicArea/delete", params, headers)
        .then((response) => {
          // 刷新页面
          this.getScenic();
          
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
  },
  data() {
    return {
      ImageUrl: "",
      scenicList: [],
      AddDialogVisible: false,
      form: {
        id: null,
        name: "",
        picture: "",
        introduce: "",
        longitude: "",
        latitude: "",
        signInScope: "",
        invokeFlag: 1,
        pictureList: [],
        videoList: [],
      },
      uploadUrl: "",
      headers: {
        token: localStorage.getItem("token"),
      },
      updateImageUrl: "",
      updateDialogVisible: false,
      updateForm: {
        id: null,
        name: "",
        picture: "",
        introduce: "",
        longitude: "",
        latitude: "",
        signInScope: "",
        invokeFlag: 1,
        pictureList: [],
        videoList: [],
      },
    };
  },
};
</script>


<style scoped>
.container {
  min-height: 600px;
}
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 12px;
}

.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
}

.el-table {
  margin-top: 15px;
  font-size: 12px;
}

.el-pagination {
  margin-top: 20px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>