<template>
    <div class="head">
      <div class="logo">
        <span>周盛欢</span>
      </div>

      <!-- <div class="tabs">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="首页" name="first"></el-tab-pane>
        </el-tabs>
      </div> -->
      <div class="nav">
        <ul>
          <li><a  @click="goHome">首页</a></li>
        </ul>
      </div>
      <div class="search">
        <input type="text" placeholder="请输入关键词" v-model="searchQuery"></input>
        <div class="search-icon" @click="goToSearch">
          <img src="../assets/sousuo.png" />
        </div>
      </div>
      <!-- 头像 -->
      <div class="avatar-view">
        
        <el-dropdown>
  <span class="el-dropdown-link">
    <el-avatar :size="35" :src="url" ></el-avatar>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item @click.native="logout">退出登陆</el-dropdown-item>

  </el-dropdown-menu>
</el-dropdown>

      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      input3: "",
      searchQuery: "",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
    };
  },
  created() {
  // 组件创建时获取查询参数
  this.searchQuery = this.$route.query.keyword || "";
},
watch: {
    // 监听路由变化
    '$route.query.keyword'(newKeyword) {
      this.searchQuery = newKeyword || '';
    }
  },
  methods: {
    goHome() {
      if (this.$route.path !== "/home") {
        this.$router.push({ path: "/home" });
      } else {
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push({ path: "/login" });
    },
    goToSearch() {
      this.$emit('search-query', this.searchQuery);
      console.log("哈哈哈哈");
      if (this.$route.path !== "/Search") {
        this.$router.push({
          path: "/Search",
          query: { keyword: this.searchQuery },
        });
        
      } else {
      }
    },
  },
};
</script>


<style scoped>
.head {
  background-color: #ffffff;
  width: 100%;
  height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.logo {
  margin-left: 60px;
  margin-bottom: 10px;
  min-width: 100px;
}
.logo span {
  color: #1772f6; /* 设置文字颜色为蓝色 */
  font-family: "宋体", SimSun, serif; /* 设置字体为宋体 */
  font-size: 25px;
}

.tabs {
  margin-left: 30px;
}

.search {
  margin-left: 100px;
  margin-bottom: 5px;
}
.search {
  display: flex; /* 启用Flexbox */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  width: 412px; /* 宽度 */
  height: 40px; /* 高度 */
  background-color: #f3f5f7; /* 背景颜色 */
  border-radius: 20px; /* 边框圆角 */
  padding-left: 19px; /* 左内边距 */
  padding-right: 12px; /* 右内边距 */
  flex-wrap: nowrap;
  min-width: 400px;
}

.search input {
  flex: 1;
  border: 0;
  background-color: transparent;
  outline: none;
}
.search-icon img {
  width: 20px;
  height: 20px;
}

.avatar-view {
  margin-left: 120px;
}

.nav {
  margin-left: 10px;
  min-width: 80px;
}

.nav ul {
  display: flex;
}

.nav ul li {
  list-style-type: none; /* 去掉列表项左边的圆点 */
}

/* 如果你想要为链接添加一些样式，比如颜色或者鼠标悬停效果，可以添加如下样式 */

.nav ul li a {
  color: #808080; /* 设置链接默认颜色为灰色 */
  text-decoration: none; /* 去掉链接下的下划线 */
  font-weight: normal; /* 默认字体不加粗 */
}

.nav ul li a:hover {
  text-decoration: underline; /* 鼠标悬停时添加下划线 */
  text-decoration-color: #0000ff; /* 设置下划线颜色为蓝色 */
}

.nav ul li a.active {
  color: #000; /* 设置选中链接颜色为黑色 */
  font-weight: bold; /* 设置选中链接字体加粗 */
}
</style>
