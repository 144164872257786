<template>
  <div class="container">
    <Header @search-query="handleSearchQuery"></Header>
    <div v-if="articles.length === 0" class="no-results">
      <div class="ArticleCard" >
     没有搜索到。。。

   </div>
    </div>
    <div class="ArticleCard" v-for="article in articles" :key="article.id"
    @click="goToArticleDetail(article.id)">
     
      <div class="title">
        {{ article.title }}
      </div>
      <div class="cover-intro">
        <div class="cover">
          <img :src="article.cover"/>
        </div>
        <div class="intro">
          {{ article.intro }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState([
      "apiUrl", // 映射 'apiUrl' 状态
    ]),
  },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "其他文章标题",
          cover:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          intro: "这是其他文章的简介。",
          content: "",
        },
        {
          id: 2,
          title: "其他文章标题",
          cover:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
          intro: "这是其他文章的简介。",
          content: "",
        },
      ],
      searchQuery: "",
    };
  },
  created() {
    // 组件创建时获取查询参数
    
    this.searchQuery = this.$route.query.keyword || "";
    console.log("搜索输入框" + this.searchQuery);
    this.searchArticles(this.searchQuery);
  },
  methods: {
    ...mapMutations([
      "updateApiUrl", // 映射 'updateApiUrl' mutation
    ]),
    ...mapGetters([
      "getApiUrl", // 映射 'getApiUrl' getter
    ]),
    searchArticles(searchQuery) {
      let ApiBaseUrl = this.getApiUrl();
      console.log("ApiBaseUrl", ApiBaseUrl);
      // 构造请求体数据
      const SearchDto = {
        searchQuery: searchQuery, // 搜索关键词
        page: 1, // 页码
        size: 10, // 每页大小
      };

      this.$http
        .post(
          ApiBaseUrl + "/api/user/article/SearchArticle",
          SearchDto,
          {
            // 请求体数据
          },
          {
            headers: {
              "Content-Type": "application/json", // 显式设置请求头为 JSON
            },
          }
        )
        .then((response) => {
          // 处理响应
          console.log(response.data);
          // 假设响应数据中包含文章列表，可以将其赋值给 articles
          this.articles = response.data.articleList;
        })
        .catch((error) => {
          // 处理错误
          console.error("请求失败:", error);
        });
    },
    handleSearchQuery(searchQuery){
      console.log('打印========' + searchQuery)
      this.searchArticles(searchQuery);
    },
    //跳转到文章详情页
    goToArticleDetail(id){
      this.$router.push({ path: "/ArticleDetail", query: { id: id } });
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* 确保body元素高度为100% */
}

.container {
  background-color: #f4f6f9;
  height: 100vh; /* 使.container高度为100%的视口高度 */
  margin: 0; /* 移除外边距 */
  padding: 0; /* 移除内边距 */
  overflow: auto;
}
.ArticleCard {
  height: 150px;
  width: 690px;
  background-color: #ffff;
  margin-top: 10px;
  margin-left: 250px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px; /* 添加内间距 */
  transition: background-color 0.3s;
  cursor: pointer;
}

.ArticleCard:hover {
  background-color: #f0f0f0; /* 鼠标悬停时的背景颜色 */
}

.cover img {
  width: 190px;
  height: 105px;
  border-radius: 10px;
}

.title {
  font-size: 18px; /* 标题字体大小 */
  font-weight: bold; /* 标题字体加粗 */
  color: #333; /* 标题字体颜色 */
  margin-bottom: 10px; /* 标题下方间距 */
}
.cover-intro {
  display: flex;
}
.intro {
  margin-left: 20px;
}
</style>
