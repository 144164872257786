<template>
    <div class="container">
      <Header ></Header>
      <div class="ArticleCard">
        <div class="article-title">{{ article.title }}</div>
        <div class="article-content" v-html="article.content"></div>
      </div>
      
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState([
      'apiUrl' // 映射 'apiUrl' 状态
    ]),
    
  },
  data() {
    return {
      id: null,
      article: {
        title: "文章标题1",
          content: "<p>，遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声</p>",
      }
    };
  },
  mounted() {
    
  },
  created() {
    // 组件创建时获取查询参数
    this.id = this.$route.query.id;
    this.GetArticleId(this.id);
  },
  methods: {
    ...mapMutations([
      'updateApiUrl' // 映射 'updateApiUrl' mutation
    ]),
    ...mapGetters([
      'getApiUrl' // 映射 'getApiUrl' getter
    ]),
    //获得文章详情
    GetArticleId(id){
      let ApiBaseUrl = this.getApiUrl();
      console.log('ApiBaseUrl', ApiBaseUrl);
      const url = `${ApiBaseUrl}/api/user/article/GetArticleId?id=${id}`;
      this.$http.get(url, {
      })
      .then(response => {
        console.log('接收到的响应:', response.data.article);
        this.article = response.data.article;
      })
      .catch(error => {
        // 处理错误
     
      });
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%; /* 确保body元素高度为100% */
}

.container {
  background-color: #f4f6f9;
  height: 100vh; /* 使.container高度为100%的视口高度 */
  margin: 0; /* 移除外边距 */
  padding: 0; /* 移除内边距 */
  overflow: auto;
}

.ArticleCard {
  margin-top: 10px;
  margin-left: 150px;
  width: 695px;
  background-color: #ffffff; /* 设置背景颜色为白色 */
  border: 1px solid #dcdcdc; /* 设置边框为1像素实线，颜色为浅灰色 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 设置阴影效果 */
  padding: 20px; /* 添加内边距 */
  min-height: 600px;
}

.article-title {
  font-size: 24px; /* 根据需要调整字体大小 */
  font-weight: bold; /* 字体加粗 */
  margin-bottom: 15px; /* 根据需要添加底部外边距 */
}

.article-content {
  font-size: 16px; /* 根据需要调整字体大小 */
  line-height: 1.6; /* 行高 */
  margin-bottom: 20px; /* 底部外边距 */
}

</style>