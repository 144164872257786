import Vue from 'vue'

import {
    Button,
    Form,
    FormItem,
    Input,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    Submenu,
    MenuItemGroup,
    MenuItem,
    Breadcrumb,
    BreadcrumbItem,
    Card,
    Row,
    Col,
    Table,
    TableColumn,
    Switch,
    Tooltip,
    Pagination,
    Dialog,
    MessageBox,
    Upload,
    Icon,
    Message, 
    Popconfirm,
    Radio,
    DatePicker,
    RadioGroup,
    Tabs,
    TabPane,
    Avatar,
    Divider,
    Dropdown,
    Popover,
    DropdownMenu,
    DropdownItem,
  } from 'element-ui';
  
  // 注册组件
  [DropdownItem, DropdownMenu, Popover, Dropdown, Divider, Avatar, TabPane, Tabs, RadioGroup, DatePicker, Radio, Popconfirm, Button, Form, FormItem, Input, Container, Header, Aside, Main, Menu, Submenu, MenuItemGroup, MenuItem, Breadcrumb, BreadcrumbItem, Card, Row, Col, Table, TableColumn, Switch, Tooltip, Pagination, Dialog, MessageBox, Upload, Icon].forEach(component => {
    Vue.component(component.name, component);
  });
  
  // 注册 MessageBox 和 Message
  Vue.component(MessageBox.name, MessageBox);
  Vue.prototype.$message = Message;