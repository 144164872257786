<template>
  <div class="container">
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-col :span="4">
        <el-button type="primary" @click="editCourse">添加用户</el-button>
      </el-col>

      <el-table :data="user" border style="width: 100%">
        <el-table-column prop="name" label="姓名" width="120">
        </el-table-column>
        <el-table-column prop="role" label="角色" width="150">
          <template slot-scope="scope">
            {{ scope.row.role === 0 ? "管理员" : "用户" }}
          </template>
        </el-table-column>
        <el-table-column prop="sex" label="性别" width="150">
          <template slot-scope="scope">
            {{ scope.row.sex === 0 ? "女" : "男" }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号码" width="120">
        </el-table-column>
        <el-table-column prop="invokeFlag" label="状态" width="120">
          <template slot-scope="scope">
            {{ scope.row.invokeFlag === 0 ? "禁用" : "启用" }}
          </template>
        </el-table-column>
        <el-table-column prop="avatar" label="头像" width="300">
          <template slot-scope="scope">
            <img :src="scope.row.avatar" style="width: 100px; height: 100px" />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="120">
        </el-table-column>
        <el-table-column prop="updateTime" label="修改时间" width="120">
        </el-table-column>
        <el-table-column prop="updateUser" label="修改人" width="120">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <!-- <el-tooltip effect="dark" content="添加" placement="top">
              <el-button
                type="primary"
                icon="el-icon-plus"
                size="mini"
                @click="editCourse(scope.row)"
              >
              </el-button>
            </el-tooltip> -->

            <el-tooltip effect="dark" content="删除" placement="top">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 编辑弹窗 -->
      <el-dialog
        title="添加用户"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="UpdateCoure" label-width="100px">
          <el-form-item label="姓名">
            <el-input v-model="UpdateCoure.name"></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input v-model="UpdateCoure.loginAccount"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <template>
              <el-radio v-model="UpdateCoure.sex" label="0">女</el-radio>
              <el-radio v-model="UpdateCoure.sex" label="1">男</el-radio>
            </template>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="UpdateCoure.phone"></el-input>
          </el-form-item>
          <el-form-item label="头像">
            <div class="rahmen">
              <el-upload
                class="avatar-uploader"
                :headers="headers"
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="updateImageUrl"
                  :src="updateImageUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAddCourse">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["apiUrl"]),
  },
  methods: {
    ...mapMutations(["updateApiUrl"]),
    ...mapGetters(["getApiUrl"]),
    handleClick(row) {
      console.log(row);
    },
    getUserList() {
      //获取所有用户
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      this.$http
        .post("/api/user/listSearch", headers)
        .then((response) => {
          // 处理响应
          this.user = response.data.data;
          console.log("this.user", this.user);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    editCourse() {
      this.dialogVisible = true;
    },
    handleDelete(row) {
      console.log("删除", row);
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      // 创建DeleteParam对象
      const deleteParam = {
        idList: [row.id],
        invokeFlag: 0,
      };
      this.$http
        .post("/api/user/delete", deleteParam, headers)
        .then((response) => {
          // 处理响应
          this.getUserList();
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleAvatarSuccess(res, file) {
      //接收服务器返回的图片地址
      if (res.data) {
        this.updateImageUrl = res.data;
        this.UpdateCoure.avatar = res.data;
      }
    },
    beforeAvatarUpload(file) {
      // 检查图片是否符合上传标准
      const isJPG = file.type === "image/jpeg";
      const acceptedMimes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!acceptedMimes.includes(file.type)) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 或 WEBP 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return acceptedMimes.includes(file.type) && isLt10M;
    },
    getUrl_() {
      let ApiBaseUrl = this.getApiUrl();
      console.log("hh", ApiBaseUrl);
      this.uploadUrl = ApiBaseUrl + "/api/file/uploadOss";
      console.log("this.getApiUrl", ApiBaseUrl);
      console.log("this.uploadUrl", this.uploadUrl);
    },
    //确定添加
    confirmAddCourse() {
      this.dialogVisible = false;
      console.log("UpdateCoure", this.UpdateCoure);

      const params = {
        loginAccount: this.UpdateCoure.loginAccount,
        name: this.UpdateCoure.name,
        avatar: this.UpdateCoure.avatar,
        sex: this.UpdateCoure.sex,
        phone: this.UpdateCoure.phone,
      };
      this.$http
        .post("/api/user/insert", params)
        .then((response) => {
          // 处理响应
          console.log(response.data);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
  },

  data() {
    return {
      tableData: [
        {
          date: "2016-05-05",
          name: "李雷",
          province: "江苏",
          city: "南京",
          address: "江苏省南京市建邺区江东中路 100 号",
          zip: 210000,
        },
      ],
      user: [],
      dialogVisible: false,
      UpdateCoure: {},
      headers: {
        token: localStorage.getItem("token"),
      },
      uploadUrl: "",
      updateImageUrl: "",
    };
  },
  created() {
    //得到所有用户
    this.getUserList();
    this.getUrl_();
    console.log("执行了嘛");
  },
};
</script>

<style scoped>
.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
}

.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 12px;
}

.container {
  min-height: 600px;
}

.el-table {
  margin-top: 15px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
