
<!-- 轮播图管理页面 -->
<template>
  <div class="container">
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 添加轮播图对话框按钮 -->
      <el-button type="primary" @click="handleAdd">添加轮播图</el-button>
      <!-- 表格 -->
      <el-table :data="BannerData" border style="width: 100%">
        <el-table-column fixed prop="id" label="id" width="100">
        </el-table-column>
        <el-table-column prop="invokeFlagStr" label="启用状态" width="100">
        </el-table-column>
        <el-table-column prop="bannerPath" label="图片" width="300">
          <template slot-scope="scope">
            <img
              :src="scope.row.bannerPath"
              style="width: 200px; height: 200px"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editBanner(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加轮播图对话框 -->
      <el-dialog
        title="添加轮播图"
        :visible.sync="addBannerDialog"
        width="50%"
        :before-close="AddBannerClose"
      >
        <el-form ref="form" :model="addForm" label-width="80px">
          <el-form-item label="图片">
            <el-upload
              class="avatar-uploader"
              :headers="headers"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addBannerDialog = false">取 消</el-button>
          <el-button type="primary" @click="addBanner_">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑轮播图对话框 -->
      <el-dialog
        title="编辑轮播图"
        :visible.sync="updateDialogVisible"
        width="50%"
        :before-close="updateHandleClose"
      >
        <el-form ref="form" :model="addForm" label-width="80px">
          <el-form-item label="图片">
            <el-upload
              class="avatar-uploader"
              :headers="headers"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="updateImageUrl" :src="updateImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateBanner">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 分页器 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalElements"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState(["apiUrl"]),
  },
  methods: {
    ...mapMutations(["updateApiUrl"]),
    ...mapGetters(["getApiUrl"]),
    getUrl_() {
      let ApiBaseUrl = this.getApiUrl();
      console.log("hh", ApiBaseUrl);
      this.uploadUrl = ApiBaseUrl + "/api/file/uploadOss";
      console.log("this.getApiUrl", ApiBaseUrl);
      console.log("this.uploadUrl", this.uploadUrl);
    },
    // 编辑轮播图
    editBanner(row) {
      console.log(row);
      //回显
      this.updateImageUrl = row.bannerPath;
      this.updateForm.id = row.id;
      this.updateDialogVisible = true;
    },
    handleDelete(row) {
      console.log("要删除的活动:", row.id);
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        idList: [row.id],
        invokeFlag: 0,
      };
      this.$http
        .post("/api/banner/delete", params, headers)
        .then((response) => {
          // 处理响应
          this.getBannerList(this.currentPage4, this.pageSize);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    //得到所有轮播图
    getBannerList(pageNumber, pageSize) {
      console.log("pageSize=====", pageSize);
      console.log("pageNumber=====", pageNumber);
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        invokeFlag: 1,
      };

      this.$http
        .post("/api/banner/pageSearch", params, headers)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.BannerData = response.data.data.data;
          this.totalElements = response.data.data.total;
          console.log("this.BannerData", this.BannerData);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    // 关闭添加轮播图对话框
    AddBannerClose() {
      this.addBannerDialog = false;
    },
    handleAdd() {
      this.addBannerDialog = true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.updateImageUrl = URL.createObjectURL(file.raw);
      if (res.data) {
        this.addForm.bannerPath = res.data;
        this.updateForm.bannerPath = res.data;
      }
    },
    // 上传图片时检查大小及格式
    beforeAvatarUpload(file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const isImage = allowedTypes.includes(file.type);
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isImage) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isImage && isLt10M;
    },
    // 添加轮播图
    addBanner_() {
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        id: null,
        invokeFlag: 1,
        bannerPath: this.addForm.bannerPath,
      };

      this.$http
        .post("/api/banner/insert", params, headers)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.getBannerList(this.currentPage4, this.pageSize);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
      this.addBannerDialog = false;
    },
    updateHandleClose() {
      this.updateDialogVisible = false;
    },
    //提交修改
    updateBanner() {
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        id: this.updateForm.id,
        invokeFlag: 1,
        bannerPath: this.updateForm.bannerPath,
      };
      this.$http
        .post("/api/banner/update", params, headers)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.getBannerList(this.currentPage4, this.pageSize);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
      this.updateDialogVisible = false;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getBannerList(this.currentPage4, this.pageSize);
      console.log("this.pageSize===", this.pageSize);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.getBannerList(this.currentPage4, this.pageSize);
      console.log("this.pageSize===", this.pageSize);
    },
  },
  data() {
    return {
      BannerData: [],
      addBannerDialog: false,
      addForm: {
        id: 0,
        invokeFlag: 0,
        bannerPath: "",
      },
      updateForm: {
        id: 0,
        invokeFlag: 0,
        bannerPath: "",
      },
      imageUrl: "",
      uploadUrl: "",
      headers: {
        token: localStorage.getItem("token"),
      },
      updateDialogVisible: false,
      updateImageUrl: "",
      currentPage4: 1,
      totalElements: 100,
      pageSize: 10,
    };
  },
  created() {
    console.log("执行了嘛");
    this.getBannerList(this.currentPage4, this.pageSize);
    this.getUrl_();
  },
};
</script>

<style scoped>
.container {
  min-height: 600px;
}
.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  margin-top: 15px;
}

.el-breadcrumb {
  width: calc(33.333% - 1px);
  font-size: 12px;
}

.container {
  min-height: 600px;
}

.el-table {
  margin-top: 15px;
}

.AddButton {
  margin-top: 15px;
}

.block {
  margin-top: 15px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
