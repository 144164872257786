<template>
  <div class="container">
    <div class="Register-view">
      <div class="image">
        <img src="../assets/login.png" />
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="ruleForm.sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >注册</el-button
          >
          <el-button type="primary" @click="gotoLogin">返回登录</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState([
      "apiUrl", // 假设你想要映射 'apiUrl' 状态
    ]),
    // 映射 Vuex getters 到计算属性
  },
  data() {
    return {
      ruleForm: {
        name: "",
        password: "",
        sex: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "输入密码", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "长度在 8 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations([
      "updateApiUrl", // 映射 'updateApiUrl' mutation
    ]),
    ...mapGetters([
      "getApiUrl", // 映射 'getApiUrl' getter
    ]),
    submitForm(formName) {
      let ApiBaseUrl = this.getApiUrl();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("ruleForm===", this.ruleForm);
          const register = {
            name: this.ruleForm.name,
            password: this.ruleForm.password,
          };
          console.log("register===", register);
          axios
            .post(ApiBaseUrl + "/api/user/web/register", register, {
              headers: {
                "Content-Type": "application/json", // 显式设置请求头为 JSON
              },
            })
            .then((response) => {
              console.log("response.====", response.data.msg);
              if (response.data.code === 1) {
                this.$message({
                  message: "注册成功",
                  type: "success",
                });
                this.$router.push("/login");
              } else {
                this.$message({
                  message: response.data.msg,
                  type: "warning",
                });
              }
            })
            .catch((error) => {
              // 请求失败
              console.error("请求错误：", error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    gotoLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f9;
  width: 100%;
  height: 100vh;
}

.Register-view {
  width: 400px;
  margin-top: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  margin-top: 30px;
}
.image img {
  width: 40px;
  height: 40px;
}

.el-form {
  margin-top: 15px;
}
</style>