<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-button type="primary" @click="handleAdd">添加活动</el-button>
      <el-table :data="ActivityList" border style="width: 100%">
        <el-table-column prop="id" label="id" width="120"> </el-table-column>
        <el-table-column prop="name" label="活动名称" width="120">
        </el-table-column>
        <el-table-column prop="picture" label="活动照片" width="320">
          <template slot-scope="scope">
            <img :src="scope.row.picture" style="width: 200px; height: 200px" />
          </template>
        </el-table-column>
        <el-table-column prop="introduce" label="活动介绍" width="120">
        </el-table-column>
        <el-table-column prop="applyStartTime" label="报名开始时间" width="300">
        </el-table-column>
        <el-table-column prop="applyEndTime" label="报名结束时间" width="300">
        </el-table-column>
        <el-table-column
          prop="activityStartTime"
          label="活动开始时间"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="activityEndTime"
          label="活动结束时间"
          width="300"
        >
        </el-table-column>
        <el-table-column prop="remark" label="活动须知" width="400">
        </el-table-column>
        <el-table-column prop="issueFlag" label="发布状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.issueFlag === 1">草稿</span>
            <span v-if="scope.row.issueFlag === 2">发布</span>
            <span v-if="scope.row.issueFlag === 3">下线</span>
          </template>
        </el-table-column>
        <el-table-column prop="invokeFlag" label="启用状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.invokeFlag === 1">启用</span>
            <span v-if="scope.row.invokeFlag === 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editActivity(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>

            <el-tooltip effect="dark" content="状态" placement="top">
              <el-button
                type="primary"
                icon="el-icon-setting"
                size="mini"
                @click="statusDialog_(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 添加活动对话框 -->
      <el-dialog
        title="添加活动"
        :visible.sync="addDialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="活动名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="活动照片">
            <el-upload
              class="avatar-uploader"
              :headers="headers"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="活动介绍">
            <el-input v-model="form.introduce"></el-input>
          </el-form-item>
          <el-form-item label="报名时间">
            <div class="block">
              <el-date-picker
                v-model="dateRange"
                type="datetimerange"
                align="right"
                start-placeholder="报名开始日期"
                end-placeholder="报名结束日期"
                :default-time="['12:00:00', '08:00:00']"
                @change="registrationTime(dateRange)"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="活动时间">
            <div class="block">
              <el-date-picker
                v-model="activityDateRange"
                type="datetimerange"
                align="right"
                start-placeholder="活动开始日期"
                end-placeholder="活动结束日期"
                :default-time="['12:00:00', '08:00:00']"
                @change="activityTime(activityDateRange)"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="活动须知">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item label="发布状态">
            <el-radio-group v-model="form.issueFlag">
              <el-radio label="草稿"></el-radio>
              <el-radio label="发布"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addActivity">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 修改窗口 -->
      <el-dialog
        title="编辑窗口"
        :visible.sync="updateDialogVisible"
        width="50%"
        :before-close="updateHandleClose"
      >
        <el-form ref="form" :model="updateForm" label-width="80px">
          <el-form-item label="活动名称">
            <el-input v-model="updateForm.name"></el-input>
          </el-form-item>
          <el-form-item label="活动照片">
            <div></div>
            <el-upload
              class="avatar-uploader"
              :headers="headers"
              :action="uploadUrl"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="updateImageUrl" :src="updateImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="活动介绍">
            <el-input v-model="updateForm.introduce"></el-input>
          </el-form-item>
          <el-form-item label="报名时间">
            <div class="block">
              <el-date-picker
                v-model="updateDateRange"
                type="datetimerange"
                align="right"
                start-placeholder="报名开始日期"
                end-placeholder="报名结束日期"
                :default-time="['12:00:00', '08:00:00']"
                @change="updateRegistrationTime(updateDateRange)"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="活动时间">
            <div class="block">
              <el-date-picker
                v-model="updateActivityDate"
                type="datetimerange"
                align="right"
                start-placeholder="活动开始日期"
                end-placeholder="活动结束日期"
                :default-time="['12:00:00', '08:00:00']"
                @change="updateActivityTime(updateActivityDate)"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="活动须知">
            <el-input v-model="updateForm.remark"></el-input>
          </el-form-item>
          <!-- <el-form-item label="发布状态">
            <el-radio-group v-model="updateForm.issueFlag">
              <el-radio label="草稿"></el-radio>
              <el-radio label="发布"></el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="updateDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateActivity">确定</el-button>
        </span>
      </el-dialog>

      <!-- 修改状态 -->
      <el-dialog
        title="修改活动的状态"
        :visible.sync="statusDialog"
        width="50%"
        :before-close="StatusHandleClose"
      >
        <div class="invokeFlag-2">
          启用状态：
          <el-radio v-model="updateStatus.invokeFlag" label="0">禁用</el-radio>
          <el-radio v-model="updateStatus.invokeFlag" label="1">启用</el-radio>
        </div>
        <div class="issueFlag-2">
          发布状态：
          <el-radio v-model="updateStatus.issueFlag" label="2">发布</el-radio>
          <el-radio v-model="updateStatus.issueFlag" label="3">下线</el-radio>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="statusDialog = false">取 消</el-button>
          <el-button type="primary" @click="ChangeStatus">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    // 映射 Vuex state 中的属性到计算属性
    ...mapState(["apiUrl"]),
  },
  methods: {
    ...mapMutations(["updateApiUrl"]),
    ...mapGetters(["getApiUrl"]),
    StatusHandleClose() {
      this.statusDialog = false;
    },
    statusDialog_(row) {
      console.log("编辑状态", row);
      this.updateStatus.id = row.id;
      //回显，暂时取消
      // if(row.invokeFlag === 1){
      //   this.updateStatus.invokeFlag = '1';
      // }else if(row.invokeFlag === 0){
      //   this.updateStatus.invokeFlag = '0';
      // }
      // this.updateStatus.issueFlag = row.issueFlag;
      this.statusDialog = true;
    },
    handleClick(row) {
      console.log(row);
    },
    getActivityList() {
      const params = {
        pageNumber: 1,
        pageSize: 10,
      };
      this.$http
        .post("/api/activity/pageSearch", params)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.ActivityList = response.data.data.data;
          console.log("this.ActivityList", this.ActivityList);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    handleDelete(row) {
      console.log("要删除的活动:", row.id);
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        idList: [row.id],
      };
      this.$http
        .post("/api/activity/delete", params, headers)
        .then((response) => {
          // 处理响应
          this.getActivityList();
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    //打开添加活动对话框
    handleAdd() {
      this.addDialogVisible = true;
      this.form = {};
    },
    handleClose() {
      this.addDialogVisible = false;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.data) {
        this.form.picture = res.data;
        this.updateImageUrl = res.data;
        this.updateForm.picture = res.data;
        console.log("this.form.picture", this.form.picture);
        console.log("this.updateForm.picture", this.updateForm.picture);
      }
    },
    beforeAvatarUpload(file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      const isLt10M = file.size / 1024 / 1024 < 10;
      const isImage = allowedTypes.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
      }
      if (!isLt10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
      }
      return isImage && isLt10M;
    },
    getUrl_() {
      let ApiBaseUrl = this.getApiUrl();
      console.log("hh", ApiBaseUrl);
      this.uploadUrl = ApiBaseUrl + "/api/file/uploadOss";
      console.log("this.getApiUrl", ApiBaseUrl);
      console.log("this.uploadUrl", this.uploadUrl);
    },
    registrationTime(dateRange) {
      const dateStrings = this.getDateRange(dateRange);
      if (dateStrings) {
        const [startStr, endStr] = dateStrings;
        this.form.applyStartTime = startStr;
        this.form.applyEndTime = endStr;
        console.log("报名开始日期:", this.form.applyStartTime);
        console.log("报名结束日期:", this.form.applyEndTime);
      }
    },
    activityTime(dateRange) {
      const dateStrings = this.getDateRange(dateRange);
      if (dateStrings) {
        const [startStr, endStr] = dateStrings;
        this.form.activityStartTime = startStr;
        this.form.activityEndTime = endStr;
        console.log("活动开始日期:", this.form.activityStartTime);
        console.log("活动结束日期:", this.form.activityEndTime);
      }
    },
    updateRegistrationTime(dateRange) {
      const dateStrings = this.getDateRange(dateRange);
      if (dateStrings) {
        const [startStr, endStr] = dateStrings;
        this.updateForm.applyStartTime = startStr;
        this.updateForm.applyEndTime = endStr;
        console.log("修改窗口报名开始日期:", this.updateForm.applyStartTime);
        console.log("修改窗口报名结束日期:", this.updateForm.applyEndTime);
      }
    },
    updateActivityTime(dateRange) {
      const dateStrings = this.getDateRange(dateRange);
      if (dateStrings) {
        const [startStr, endStr] = dateStrings;
        this.updateForm.activityStartTime = startStr;
        this.updateForm.activityEndTime = endStr;
        console.log("修改窗口活动开始日期:", this.updateForm.activityStartTime);
        console.log("修改窗口活动结束日期:", this.updateForm.activityEndTime);
      }
    },
    getDateRange(dateRange) {
      if (dateRange && dateRange.length > 1) {
        // 获取开始日期时间
        const startDate = dateRange[0];
        // 获取结束日期时间
        const endDate = dateRange[1];
        // 转换开始日期时间为 "YYYY-MM-DD HH:MM:SS" 格式
        const startStr = startDate
          .toISOString()
          .replace(/T/, " ")
          .substring(0, 19);
        // 转换结束日期时间为 "YYYY-MM-DD HH:MM:SS" 格式
        const endStr = endDate.toISOString().replace(/T/, " ").substring(0, 19);
        return [startStr, endStr];
      }
    },
    // 增加新活动
    addActivity() {
      this.addDialogVisible = false;
      console.log("this.form===", this.form);
      const headers = {
        "Content-Type": "application/json",
      };
      // 默认是草稿
      let issueFlag_ = 1;
      if (this.form.issueFlag === "草稿") {
        issueFlag_ = 1;
      } else if (this.form.issueFlag === "发布") {
        issueFlag_ = 2;
      }
      this.form.issueFlag;
      const params = {
        invokeFlag: 1,
        name: this.form.name,
        picture: this.form.picture,
        introduce: this.form.introduce,
        applyStartTime: this.form.applyStartTime,
        applyEndTime: this.form.applyEndTime,
        activityStartTime: this.form.activityStartTime,
        activityEndTime: this.form.activityEndTime,
        remark: this.form.remark,
        issueFlag: issueFlag_,
        pictureList: ["string"],
        videoList: ["string"],
      };
      this.$http
        .post("/api/activity/insert", params, headers)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.getActivityList();
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    updateHandleClose() {
      this.updateDialogVisible = false;
    },
    OpenEditWindow() {
      this.updateDialogVisible = true;
    },
    // 编辑活动
    editActivity(activity) {
      this.updateForm = { ...activity };
      this.updateImageUrl = this.updateForm.picture;
      console.log("this.updateForm", this.updateForm);
      this.updateDateRange = [
        this.updateForm.applyStartTime,
        this.updateForm.applyEndTime,
      ];
      this.updateActivityDate = [
        this.updateForm.activityStartTime,
        this.updateForm.activityEndTime,
      ];
      this.updateDialogVisible = true;
    },
    // 提交修改
    updateActivity() {
      console.log("updateForm", this.updateForm);
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        id: this.updateForm.id,
        invokeFlag: 1,
        name: this.updateForm.name,
        picture: this.updateForm.picture,
        introduce: this.updateForm.introduce,
        applyStartTime: this.updateForm.applyStartTime,
        applyEndTime: this.updateForm.applyEndTime,
        activityStartTime: this.updateForm.activityStartTime,
        activityEndTime: this.updateForm.activityEndTime,
        remark: this.updateForm.remark,
        issueFlag: 1,
        pictureList: ["string"],
        videoList: ["string"],
      };
      this.$http
        .post("/api/activity/update", params, headers)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.getActivityList();
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
      this.updateDialogVisible = false;
    },
    //提交修改状态
    ChangeStatus() {
      console.log("this.updateStatus", this.updateStatus);
      let invokeFlag_ = 1;
      if(this.updateStatus.invokeFlag === '0'){
        invokeFlag_ = 0;
      }else if(this.updateStatus.invokeFlag === '1'){
        invokeFlag_ = 1;
      }
      let issueFlag_ = 1;
      if(this.updateStatus.issueFlag === '2'){
        issueFlag_ = 2;
      }else if(this.updateStatus.issueFlag === '3'){
        issueFlag_ = 3;
      }
      console.log('issueFlag_',issueFlag_);
      console.log('invokeFlag_',invokeFlag_);
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        id: this.updateStatus.id,
        invokeFlag: invokeFlag_,
        issueFlag: issueFlag_,
      };
      this.$http
        .post("/api/activity/operateActivity", params, headers)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.getActivityList();
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
        this.statusDialog = false;
    },
  },
  created() {
    console.log("刷新没");
    this.getActivityList();
    this.getUrl_();
  },
  data() {
    return {
      statusDialog: false,
      tableData: [
        {
          name: "黄山日出之旅",
          photo: "",
          description: "黄山，中国著名的风景名山，日出时分尤为壮观。",
          notice: "请提前预订门票，穿着舒适的徒步鞋。",
        },
      ],
      // 活动列表
      ActivityList: [],
      addDialogVisible: false,
      updateDialogVisible: false,
      form: {
        name: "",
        picture: "",
        introduce: "",
        applyStartTime: "",
        applyEndTime: "",
        activityStartTime: "",
        activityEndTime: "",
        remark: "",
        issueFlag: 1, // 默认为草稿状态
      },
      updateForm: {
        name: "",
        picture: "",
        introduce: "",
        applyStartTime: "",
        applyEndTime: "",
        activityStartTime: "",
        activityEndTime: "",
        remark: "",
        issueFlag: 1, // 默认为草稿状态
      },
      imageUrl: "",
      headers: {
        token: localStorage.getItem("token"),
      },
      uploadUrl: "",
      dateRange: [],
      activityDateRange: [],
      updateImageUrl: "",
      updateDateRange: [],
      updateActivityDate: [],
      updateStatus: {
        id: 0,
        invokeFlag: "1",
        issueFlag: "2",
      },
    };
  },
};
</script>

<style scoped>
.container {
  min-height: 600px;
}
.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  margin-top: 15px;
}

.el-breadcrumb {
  width: calc(33.333% - 1px);
  font-size: 12px;
}

.container {
  min-height: 600px;
}

.el-table {
  margin-top: 15px;
}

.AddButton {
  margin-top: 15px;
}

.block {
  margin-top: 15px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.issueFlag-2 {
  margin-top: 15px;
}
</style>
