<template>
  <div class="container">
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>课程类型</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <div class="AddButton">
        <el-row :gutter="25">
          <el-col :span="8">
            <el-input
              placeholder="请输入内容"
              v-model="SearchInput"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="search_"
              ></el-button>
            </el-input>
          </el-col>

          <el-button type="primary" @click="handleAddType">添加类型</el-button>
        </el-row>
      </div>

      <el-table :data="courseType" border style="width: 100%">
        <el-table-column fixed prop="id" label="id" width="100">
        </el-table-column>
        <el-table-column prop="name" label="类型" width="300">
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editCourse(scope.row)"
              >
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="handleDelete(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 编辑类型 -->
      <el-dialog
        title="编辑课程类型"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-form ref="form" :model="show" label-width="80px">
          <el-form-item label="课程类型">
            <el-input v-model="show.name"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>

    <!-- 分页器 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 20, 30, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalElements"
      >
      </el-pagination>
    </div>

    <!-- 添加类型 -->
    <el-dialog
      title="添加课程类型"
      :visible.sync="AddType"
      width="30%"
      :before-close="handleAddClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="课程类型">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="AddType = false">取 消</el-button>
        <el-button type="primary" @click="executeAddType">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick(row) {
      console.log(row);
    },
    //得到所有课程类型
    getCourseType(pageNumber, pageSize) {
      const params = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        name: this.SearchInput,
      };
      this.$http
        .post("/api/courseType/pageSearch", params)
        .then((response) => {
          // 处理响应
          console.log(response.data);
          this.courseType = response.data.data.data;
          this.totalElements = response.data.data.total;
          console.log("this.courseType", this.courseType);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    handleDelete(row) {
      console.log("要删除的课程类型:", row.id);
      // 设置请求头
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        idList: [row.id],
      };
      this.$http
        .post("/api/courseType/delete", params, headers)
        .then((response) => {
          // 处理响应
          this.getCourseType(this.currentPage4, this.pageSize);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
    },
    //关闭编辑窗口
    handleClose(done) {
      this.dialogVisible = false;
      this.getCourseType(this.currentPage4, this.pageSize);
      console.log("关闭窗口");
    },
    handleAddType() {
      console.log("打开窗口");
      this.form.name = "";
      this.AddType = true;
    },
    // 回显内容  打开窗口
    editCourse(course) {
      console.log("course.id", course.id);
      this.show = course;
      this.dialogVisible = true;
    },
    //提交修改
    onSubmit() {
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        id: this.show.id,
        invokeFlag: 1,
        name: this.show.name,
      };
      this.$http
        .post("/api/courseType/update", params, headers)
        .then((response) => {
          // 处理响应
          this.getCourseType(this.currentPage4, this.pageSize);
        })
        .catch((error) => {
          // 处理错误
          console.error(error);
        });
      this.dialogVisible = false;
      this.getCourseType(this.currentPage4, this.pageSize);
    },
    handleAddClose() {
      this.AddType = false;
    },
    //添加课程类型
    executeAddType() {
      const headers = {
        "Content-Type": "application/json",
      };
      const params = {
        id: null,
        invokeFlag: 1,
        name: this.form.name,
      };
      this.$http
        .post("/api/courseType/insert", params, headers)
        .then((response) => {
          // 处理响应
          this.getCourseType(this.currentPage4, this.pageSize);
        })
        .catch((error) => {
          // 处理错误
          this.getCourseType(this.currentPage4, this.pageSize);
          console.error(error);
        });

      this.AddType = false;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getCourseType(this.currentPage4, this.pageSize);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.getCourseType(this.currentPage4, this.pageSize);
    },
    //搜索框
    search_() {
      this.getCourseType(this.currentPage4, this.pageSize);
    },
  },

  data() {
    return {
      courseType: [],
      dialogVisible: false,
      show: {},
      form: {
        name: "",
      },
      AddType: false,
      AddName: "",
      currentPage4: 1,
      pageSize: 10,
      totalElements: 0,
      SearchInput: "",
    };
  },
  created() {
    console.log("执行了嘛");
    this.getCourseType(this.currentPage4, this.pageSize);
  },
};
</script>

<style scoped>
.el-table {
  display: table; /* 确保el-table表现得像一个表格 */
  width: 100%;
  table-layout: auto; /* 允许表格根据内容调整列宽 */
}

.el-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  margin-top: 15px;
}

.el-breadcrumb {
  width: calc(33.333% - 1px);
  font-size: 12px;
}

.container {
  min-height: 600px;
}

.el-table {
  margin-top: 15px;
}

.AddButton {
  margin-top: 15px;
}

.block {
  margin-top: 15px;
}
</style>
