import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login.vue'
import Home from '@/components/Home.vue'
import Welcome from '@/components/Welcome.vue'
import Course from '@/components/Course.vue'
import User from '@/components/User.vue'
import Scenery from '@/components/Scenery.vue'
import Activity from '@/components/Activity.vue'
import ClockIn from '@/components/ClockIn.vue'
import CourseType from '@/components/CourseType.vue'
import Banner from '@/components/Banner.vue'
import Register from '@/components/Register.vue'
import Search from '@/components/Search.vue'
import ArticleDetail from '@/components/ArticleDetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login', // 访问路径
    component: Login // 对应的组件
  },
  {
    path: '/register', // 访问路径
    component: Register // 对应的组件
  },
  {
    path: '/ArticleDetail', // 访问路径
    component: ArticleDetail // 对应的组件
  },
  {
    path: '/Search', // 访问路径
    component: Search // 对应的组件
  },
  {
    path: '/home', // 访问路径
    component: Home, // 对应的组件
  //   redirect: '/welcome',
  //   children:[
  //     { path: '/welcome',component: Welcome},
  //     { path: '/course',component: Course },
  //     { path: '/user',component: User },
  //     { path: '/scenery',component: Scenery },
  //     { path: '/activity',component: Activity },
  //     { path: '/clock',component: ClockIn },
  //     { path: '/type',component: CourseType },
  //     { path: '/banner',component: Banner },
  // ]
  }
]

const router = new VueRouter({
  routes
})

// 路由导航守卫 token不为空则放行
router.beforeEach((to, from, next) => {
    if(to.path === '/login') return next()
    if(to.path === '/register') return next()
    const token = localStorage.getItem("token")
  if(!token) return next('/login')
  next()
})

export default router
